import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataTablesResponse } from '@models/datatables-response';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getDatatable(entity,dataTablesParameters){
    return this.http
			.post<DataTablesResponse>(
				`${this.baseUrl}/api/${entity}/datatable`,
				dataTablesParameters, {}
			)
  }

  all(entity){
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }

  get(entity) {
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }

  create(entity,obj) {
    return this.http.post<any>(`${this.baseUrl}/api/${entity}`,obj);
  }

  edit(entity,obj) {
    return this.http.put<any>(`${this.baseUrl}/api/${entity}`,obj);
  }

  erase(entity,id) {
    return this.http.delete<any>(`${this.baseUrl}/api/${entity}/${id}`);
  }
}
