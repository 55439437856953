import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import {
  AuthGuardService as AuthGuard
} from './auth/auth-guard.service';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  /*{
      path: '',
      redirectTo: 'authentication/sign-in',
      pathMatch: 'full',
  },*/
  {
    path: '',
    component: CommonLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'configuracion',
        loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      },
      {
        path: 'mi-empresa',
        loadChildren: () => import('./mi-empresa/mi-empresa.module').then(m => m.MiEmpresaModule)
      },
      {
        path: 'acreditacion',
        loadChildren: () => import('./acreditacion/acreditacion.module').then(m => m.AcreditacionModule)
      },
     /* {
        path: 'cuadrillas',
        loadChildren: () => import('./crews/crews.module').then(m => m.CrewsModule)
      },*/
      /*{
        path: 'seguimiento',
        loadChildren: () => import('./seguimiento/seguimiento.module').then(m => m.SeguimientoModule)
      },
      {
        path: 'stopwork',
        loadChildren: () => import('./stopwork/stopwork.module').then(m => m.StopworkModule)
      },*/
      {
        path: 'ajustes',
        loadChildren: () => import('./ajustes/ajustes.module').then(m => m.AjustesModule)
      },
      {
        path: 'trabajadores',
        loadChildren: () => import('./trabajadores/trabajadores.module').then(m => m.TrabajadoresModule)
      },
     /* {
        path: 'vehiculos',
        loadChildren: () => import('./vehiculos/vehiculos.module').then(m => m.VehiculosModule)
      },*/
      /*{
        path: 'obrascontratista',
        loadChildren: () => import('./obrascontratista/obrascontratista.module').then(m => m.ObrascontratistaModule)
      },
      {
        path: 'obrasgestordecontrato',
        loadChildren: () => import('./obrasgestordecontrato/obrasgestordecontrato.module').then(m => m.ObrasgestordecontratoModule)
      },*/
      {
        path: 'documentoscontratista',
        loadChildren: () => import('./documentoscontratista/documentoscontratista.module').then(m => m.DocumentoscontratistaModule)
      },
      {
        path: 'contratos',
        loadChildren: () => import('./contratos/contratos.module').then(m => m.ContratosModule)
      },
      {
        path: 'importxls',
        loadChildren: () => import('./importxls/importxls.module').then(m => m.ImportxlsModule)
      },
      {
        path: 'empresa_contratos',
        loadChildren: () => import('./empresa_contratos/contratos.module').then(m => m.ContratosModule)
      },
      {
        path: 'kpi',
        loadChildren: () => import('./kpi/kpi.module').then(m => m.KpiModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'man-hours',
        loadChildren: () => import('./man-hours/man-hours.module').then(m => m.ManHoursModule)
      },
      {
        path: 'oec',
        loadChildren: () => import('./operational-excellence-center/operational-excellence-center.module').then(m => m.OperationalExcellenceCenterModule)
      },
      {
        path: 'branches',
        loadChildren: () => import('./branches/branches.module').then(m => m.BranchesModule)
      },
      /*{
        path: 'iper',
        loadChildren: () => import('./iper/iper.module').then(m => m.IperModule)
      },*/
      {
        path: 'work-permits',
        loadChildren: () => import('./work-permits/work-permits.module').then(m => m.WorkPermitsModule)
      },
      {
        path: 'entrance',
        loadChildren: () => import('./entrance/entrance.module').then(m => m.EntranceModule)
      },
      {
        path: 'ayuda',
        loadChildren: () => import('./ayuda/ayuda.module').then(m => m.AyudaModule)
      },
      {
        path: 'map-routes',
        loadChildren: () => import('./map-routes/map-routes.module').then(m => m.MapRoutesModule)
      },
      {
        path: 'reposited-document',
        loadChildren: () => import('./reposited-document/reposited-document.module').then(m => m.RepositedDocumentModule)
      },
    /*  {
        data: { moduleName: 'planes' },
        path: 'planes',
        loadChildren: () => import('./planes/planes.module').then(m => m.PlanesModule)
      },*/
    /*  {
        data: { moduleName: 'capacitaciones' },
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
      },*/
      {
        data: { moduleName: 'Historial de actividad' },
        path: 'historial-de-actividad',
        loadChildren: () => import('./activity-history/activity-history.module').then(m => m.ActivityHistoryModule)
      },
    ]
  },
  {
    path: '',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.modules').then(m => m.AuthenticationModule)
      }
    ]
  },
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, 
      { preloadingStrategy: PreloadAllModules, useHash: true, relativeLinkResolution: 'legacy' }
      )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
