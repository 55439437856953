import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '@models/user';
import { DataTablesResponse } from '@models/datatables-response';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { CoreService } from '../core.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public user: any = null;
  public modalVideoShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public permissions: any = [];

  public modulePermissionLayout = {
    SETTINGS: {
      MODULE_ID: 1,
      PERMISSIONS:
      {
        USERS: {
          VIEW: 1,
          CREATE: 4,
          EDIT: 2,
          DELETE: 3,
        },
        COMPANIES: {
          VIEW: 13,
          CREATE: 14,
          EDIT: 15,
          DELETE: 16,
        },
        PROFILES: {
          MODULE_PERMISSIONS: 17,
          ROLES: 18,
        },
        PARAMETERS: {
          MANAGE: 19,
        },
      }
    },
    CONTRACTOR: {
      MODULE_ID: 3,
      PERMISSIONS: {
        GLOBAL: {
          MANAGE: 21
        }
      }
    },
    USERS: {
      MODULE_ID: 2,
      PERMISSIONS: {
        GLOBAL: {
          VIEW: 7,
          CREATE: 5,
          EDIT: 6,
          DELETE: 8,
        }
      }
    },
    EMPLOYEES: {
      MODULE_ID: 4,
      PERMISSIONS: {
        GLOBAL: {
          VIEW: 26,
          CREATE: 27,
          EDIT: 28,
          DELETE: 29,
        }
      }
    },
    ACTIVES: {
      MODULE_ID: 5,
      PERMISSIONS: {
        VEHICLES: {
          VIEW: 30,
          CREATE: 32,
          EDIT: 31,
          DELETE: 33,
        }
      }
    },
    ACREDITATION: {
      MODULE_ID: 6,
      PERMISSIONS: {
        GLOBAL: {
          MANAGE: 34,
          CONTRACT_MANAGER: 38,
          VISUALIZER: 39,
          REVISOR_WEB: 40,
          SUPERVISOR_WEB: 41,
        },
        REVIEWERS: {
          MANAGE: 35
        },
        DOCUMENTS: {
          LOAD: 34
        }
      }
    },
    WORKS: {
      MODULE_ID: 7,
      PERMISSIONS: {
        GLOBAL: {
          HSEQ: 42
        }
      }
    },
    HH: {
      MODULE_ID: 8,
      PERMISSIONS: {
        GLOBAL: {
          VIEW: 43,
          EDIT: 44,
          REVIEW: 45,
          LOG: 46,
          FILTER_CONTRACTOR: 47
        }
      }
    },
    MAP_ROUTES: {
      MODULE_ID: 10,
      PERMISSIONS: {
        GLOBAL: {
          MANAGE: 51,
        }
      }
    },
    CUSTOM_PLAN: {
      MODULE_ID: 12,
      PERMISSIONS: {
        GLOBAL: {
          ADMIN: 53,
        }
      }
    },
    COURSES: {
      MODULE_ID: 13,
      PERMISSIONS: {
        GLOBAL: {
          ADMIN: 58,
          QUESTIONNAIRE: 57,
          PROGRAMMING: 56,
          RELATOR: 55,
        }
      }
    },
    REPO:{
      MODULE_ID: 14,
      PERMISSIONS: {
        GLOBAL: {
          HSEQ:59,
          GC:60
        }
      }
    },
  };
  public modulesPermission = null;
  public modulePreChecked = {};
  public subModulePreChecked = {};
  baseUrl = environment.baseUrl;
  public current_company_id = null;
  token: any
  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.checkUser();
  }
  checkUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    let token = JSON.parse(localStorage.getItem('token'));
    if (token) { this.token = "Bearer " + token.token }
    if (user) {
      this.user = user;
      this.modalVideoShow.next(this.user.video);
      this.permissions = [];
      if ((typeof this.user.roles) !== 'undefined')
        for (let i = 0; i < this.user.roles.length; i++) {
          for (let j = 0; j < this.user.roles[i].role.role_permissions.length; j++) {
            if (this.user.roles[i].role.role_permissions[j].permission != null)
              this.permissions.push(this.user.roles[i].role.role_permissions[j].permission);
          }
        }
      if (this.isDefined(this.user, 'company_id')) {
        this.current_company_id = this.user.company_id;
      }
    }
    this.modulesPermission = this.checkModulesPermission();
  }

  checkRolUser(rol) {
    let check = false;
    if ((typeof this.user.roles) !== 'undefined') {
      for (let i in this.user.roles) {
        if (this.user.roles[i].role_id == rol) {
          check = true;
          break;
        }
      }
    }
    return check;
  }
  checkModulesPermission() {
    let copyModulePermissionLayout = JSON.parse(JSON.stringify(this.modulePermissionLayout));
    for (let i = 0; i < this.permissions.length; i++) {
      for (let CUR_MODULE in copyModulePermissionLayout) {
        //console.log('CUR_MODULE',CUR_MODULE);
        if (copyModulePermissionLayout[CUR_MODULE].MODULE_ID == this.permissions[i].module_id) {
          //for(let INDEX in copyModulePermissionLayout[CUR_MODULE].PERMISSIONS){
          //console.log('INDEX',INDEX);
          //for(let SUBMODULE in copyModulePermissionLayout[CUR_MODULE].PERMISSIONS[INDEX]){
          for (let SUBMODULE in copyModulePermissionLayout[CUR_MODULE].PERMISSIONS) {
            //console.log('SUBMODULE',SUBMODULE);
            for (let PERMISSION in copyModulePermissionLayout[CUR_MODULE].PERMISSIONS[SUBMODULE]) {
              //console.log('PERMISSION',PERMISSION);
              if (copyModulePermissionLayout[CUR_MODULE].PERMISSIONS[SUBMODULE][PERMISSION] == this.permissions[i].id) {
                copyModulePermissionLayout[CUR_MODULE].PERMISSIONS[SUBMODULE][PERMISSION] = true;
              }
            }
          }
          //}
        }
      }
    }
    //console.log('permissions', this.permissions);
    //console.log(copyModulePermissionLayout)
    return copyModulePermissionLayout;
  }
  checkModule(selected_module) {
    let have = false;
    if (typeof this.modulePreChecked[selected_module] === 'undefined') {
      for (let CUR_MODULE in this.modulesPermission) {
        //console.log('CUR_MODULE',CUR_MODULE);
        if (CUR_MODULE == selected_module) {
          //for(let INDEX in this.modulesPermission[CUR_MODULE].PERMISSIONS){
          //console.log('INDEX',INDEX);
          //for(let SUBMODULE in this.modulesPermission[CUR_MODULE].PERMISSIONS[INDEX]){
          for (let SUBMODULE in this.modulesPermission[CUR_MODULE].PERMISSIONS) {
            //console.log('SUBMODULE',SUBMODULE);
            for (let PERMISSION in this.modulesPermission[CUR_MODULE].PERMISSIONS[SUBMODULE]) {
              //console.log('PERMISSION',PERMISSION);
              if (this.modulesPermission[CUR_MODULE].PERMISSIONS[SUBMODULE][PERMISSION] === true) {
                have = true;
              }
            }
          }
          //}
        }
      }
      this.modulePreChecked[selected_module] = have;
    } else {
      have = this.modulePreChecked[selected_module];
    }
    return have;
  }
  checkSubModule(selected_module, selected_sub_module) {
    let have = false;
    let check = false;
    if (typeof this.subModulePreChecked[selected_module] === 'undefined') {
      check = true;
    } else {
      if (typeof this.subModulePreChecked[selected_module][selected_sub_module] === 'undefined') {
        check = true;
      }
    }
    if (check) {
      for (let CUR_MODULE in this.modulesPermission) {
        //console.log('CUR_MODULE',CUR_MODULE);
        if (CUR_MODULE == selected_module) {
          //for(let INDEX in this.modulesPermission[CUR_MODULE].PERMISSIONS){
          //console.log('INDEX',INDEX);
          //for(let SUBMODULE in this.modulesPermission[CUR_MODULE].PERMISSIONS[INDEX]){
          for (let SUBMODULE in this.modulesPermission[CUR_MODULE].PERMISSIONS) {
            //console.log('SUBMODULE',SUBMODULE);
            if (SUBMODULE == selected_sub_module) {
              for (let PERMISSION in this.modulesPermission[CUR_MODULE].PERMISSIONS[SUBMODULE]) {
                //console.log('PERMISSION',PERMISSION);
                if (this.modulesPermission[CUR_MODULE].PERMISSIONS[SUBMODULE][PERMISSION] === true) {
                  have = true;
                }

              }
            }
          }
          //}
        }
      }
      if (typeof this.subModulePreChecked[selected_module] === 'undefined') {
        this.subModulePreChecked[selected_module] = {};
      }
      this.subModulePreChecked[selected_module][selected_sub_module] = have;
    } else {
      have = this.subModulePreChecked[selected_module][selected_sub_module];
    }
    return have;
  }
  checkPermission(cmodule, submodule, permission) {
    try {
      return (this.modulesPermission[cmodule].PERMISSIONS[submodule][permission] === true);
    } catch (e) {
      return false;
    }
  }
  private isDefined(obj, property) {
    return ((typeof obj[property]) !== 'undefined');
  }
  getDatatable(id_company, dataTablesParameters) {
    return this.http
      .post<DataTablesResponse>(
        `${this.baseUrl}/api/user/datatable/` + id_company,
        dataTablesParameters, {}
      )
  }
  getUser() {
    return this.http.get<any>(`${this.baseUrl}/api/auth/user`);
  }
  login(email: string, password: string) {
    return this.http.post<any>(`${this.baseUrl}/api/auth/login`, { email: email, password: password })
      .pipe(map(token => {
        // login successful if there's a jwt token in the response
        if (token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('token', JSON.stringify(token));
          this.token = "Bearer " + token.token
          if (!environment.production)
            console.log(token);
          this.getUser().subscribe(async (data) => {
            if (!environment.production)
              console.log('login getUser', data);
            if (this.isDefined(data, 'user')) {
              let isPlanReviewer = await this.http.get<any>(`${this.baseUrl}/api/isPlanReviewer`).toPromise();
              data.user.isReviewer = isPlanReviewer.response.isReviewer;
              localStorage.setItem('user', JSON.stringify(data.user));
              this.user = data.user;
              this.checkUser();
              this.router.navigate(['/dashboard']);
            }
          });
        }
        return token;
      }));
  }

  reset_pass(email: string) {
    return this.http.post<any>(`${this.baseUrl}/api/user/reset-pass`, { email: email });
  }

  create(user) {
    return this.http.post<any>(`${this.baseUrl}/api/user`, user);
  }

  edit(user) {
    return this.http.put<any>(`${this.baseUrl}/api/user`, user);
  }

  erase(id) {
    return this.http.delete<any>(`${this.baseUrl}/api/user/${id}`);
  }

  logout(redirect?) {
    // remove user from local storage to log user out
    localStorage.clear();
    this.user = null;
    this.permissions = [];
    this.modulesPermission = {};
    this.modulePreChecked = {};
    this.subModulePreChecked = {};
    this.current_company_id = null;
    this.modulesPermission = this.checkModulesPermission();
    //this.checkUser();
    if((typeof redirect)=='undefined')
    this.router.navigate(['authentication/sign-in']);
  }
}
