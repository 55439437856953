import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { UsersService } from './users/users.service';
import { PermissionsService } from './permissions/permissions.service';
import { CompanyService } from './companies/company.service';
import { InsurancesService } from './insurances/insurances.service';
import { GyresService } from './gyres/gyres.service';
import { DocumentsService } from './parameters/documents.service';
import { CurrenciesService } from './parametros/currencies.service';
import { LanguagesService } from './parametros/languages.service';
import { CountriesService } from './parametros/countries.service';
import { AcreditationService } from './acreditation/acreditation.service';
import { ConditionsService } from './parametros/conditions.service';
import { GenericService } from './generic/generic.service';
import { ExcelService } from './excel/excel.service'

import { ChecksService } from './check/check.service';

import { PlanService } from './plan/plan.service';
@Injectable({
  providedIn: 'root'
})
export class CoreService {

  public baseUrl = environment.baseUrl;
  public environment: any = environment;
  public pagesDT: any = [];
  lang = environment.baseLang;
  country = environment.baseCountry;
  public fileSize = environment.fileSize;
  public fileSizeText = environment.fileSizeText;
  public qa = environment.qa;

  constructor(
    public users: UsersService,
    public permissions: PermissionsService,
    public company: CompanyService,
    public companyInsurance: InsurancesService,
    public companyGyre: GyresService,
    public document: DocumentsService,
    public currencies: CurrenciesService,
    public languages: LanguagesService,
    public countries: CountriesService,
    public acreditation: AcreditationService,
    public conditions: ConditionsService,
    public generic: GenericService,
    public excel: ExcelService,
    public checks: ChecksService,
    public plan: PlanService
  ) {

    if(localStorage.getItem('lang') != null){
      this.lang = localStorage.getItem('lang');
    }else{
      this.lang = environment.baseLang;
      localStorage.setItem('lang',this.lang);
    }

   }


  isDefined(obj, property) {
    return ((typeof obj[property]) !== 'undefined');
  }
  refresh(): void {
    window.location.reload();
  }
  isValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  searchIdInArray(value, name, array) {
    if ((typeof array) !== 'undefined') {
      if ((typeof value) !== 'undefined') {
        if (array.length > 0) {
          for (var i = 0; i < array.length; i++) {
            console.log('array', i, array[i][name]);
            if (value == array[i][name]) {
              return array[i];
            }
          }
        }
      }
    }
    return false;

  }

  getFile(path) {

    if (path != null && path != '' && path != 'null')
      return `${this.baseUrl}/api/file/download?path=${path}`;
    else
      return `${this.baseUrl}/api/file/download?path=img/placeholders/companyplaceholder.png`;
  }
  openFile(path) {
    if (path != null && path != '' && path != 'null')
      return `${this.baseUrl}/api/file/open?path=${path}`;
    else
      return `${this.baseUrl}/api/file/open?path=img/placeholders/companyplaceholder.png`;
  }

  changeLang(lang){
    localStorage.setItem('lang',lang);
    window.location.reload();
  }
}
