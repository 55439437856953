export const environment = {
    production: true,
    qa: false,
    baseCountry: 'chile',
    baseLang: 'es-CL',
    baseUrl: 'https://back.dhl.ng.wisefollowback.com',
    cdn:'https://s3.amazonaws.com/wisefollow/', 
    fileSize:50000000,
    fileSizeText:'50MB'
  };
