<div class="app" [ngClass]="
{
  'is-collapsed': app.layout.isMenuCollapsed,
  'is-opened': app.layout.isMenuOpened,
  'rtl' : app.layout.rtlActived
}">
  <div class="layout" [ngClass]="headerSelected">
    <div [ngClass]="sidenavSelected">
      <!-- Side Nav START -->
      <div class="side-nav" sideBar>
        <div class="side-nav-inner">
          <div class="side-nav-logo logoPosCustom">
            <a *ngIf="!core.users.checkSubModule('ACREDITATION','GLOBAL')" (click)="navigateTo('/dashboard')">
              <div class="logo logo-dark"
                style="display:flex; justify-content:center; background-image: url('assets/images/clientes/wise/LogoWiseFollow.png')">
              </div>
            </a>
            <a *ngIf="core.users.checkSubModule('ACREDITATION','GLOBAL')" (click)="navigateTo('/branches/map')"
              class="logoPosCustom">
              <div class="logo logo-dark"
                style="background-image: url('assets/images/clientes/wise/LogoWiseFollow.png')"></div>
            </a>
            <div class="mobile-toggle side-nav-toggle">
              <a href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
          <ul class="side-nav-menu scrollable">
            <li [hidden]="core.users.checkSubModule('ACREDITATION','GLOBAL')" clas="nav-item" routerLinkActive="active">
              <a class="mrg-top-30" (click)="navigateTo('/dashboard')">
                <span class="icon-holder">
                  <i class="ti-home"></i>
                </span>
                <span class="title">{{ 'general.inicio' | translate }}</span>
              </a>
            </li>

            <li [hidden]="!core.users.checkSubModule('ACREDITATION','GLOBAL')" clas="nav-item"
              routerLinkActive="active">
              <a class="mrg-top-30" (click)="navigateTo('/branches/map')">
                <span class="icon-holder">
                  <i class="ti-home"></i>
                </span>
                <span class="title">{{'general.inicio' | translate}}</span>
              </a>
            </li>

            <li [hidden]="!core.users.checkSubModule('ACREDITATION','GLOBAL')" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bookmark-alt"></i>
                </span>
                <span class="title">{{'general.instalaciones.instalaciones' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li
                  [hidden]="subContract||!core.users.checkSubModule('ACREDITATION','REVIEWERS')||core.users.checkModule('CONTRACTOR')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')">
                  <a href="" [routerLink]="['/branches/new']">{{'general.instalaciones.nuevaInst' | translate}}</a>
                </li>
                <li>
                  <a href="" [routerLink]="['/branches/list']">{{'general.instalaciones.listaInst' | translate}}</a>
                </li>
              </ul>
            </li>




            <li [hidden]="!core.users.checkSubModule('ACREDITATION','GLOBAL')||true" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-alert"></i>
                </span>
                <span class="title">Matriz Iper</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="" [routerLink]="['/iper/nuevaevaluacion']">Crear nueva evaluación</a>
                </li>
                <li>
                  <a href="" [routerLink]="['/iper/listaevaluaciones']">Lista de evaluaciones</a>
                </li>
                <li>
                  <a href="" [routerLink]="['/iper/listaprocesos']">Lista de procesos</a>
                </li>
              </ul>
            </li>

            <!-- HSEQ -->
            <li
              [hidden]="(!core.users.checkSubModule('ACREDITATION','GLOBAL')&&!(core.users.checkModule('CONTRACTOR')))"
              class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bookmark-alt"></i>
                </span>
                <span class="title">{{'general.acreditacion.acreditacion' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">

                <li
                  [hidden]="subContract||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')||core.users.checkPermission('ACREDITATION','GLOBAL','SUPERVISOR_WEB')">
                  <a (click)="navigateTo('/acreditacion/nuevocontratista')">{{core.users.checkModule('CONTRACTOR')?('general.acreditacion.nuevoSub'
                    | translate):('general.acreditacion.nuevoCon' | translate)}}</a>
                </li>
                <li [hidden]="(subContract&&core.users.checkModule('CONTRACTOR'))">
                  <a (click)="navigateTo('/acreditacion/revisioncontratista')">{{core.users.checkModule('CONTRACTOR')?('general.acreditacion.listaSub'
                    | translate):('general.acreditacion.listaCon' | translate)}}</a>
                </li>
                <li
                  [hidden]="core.users.checkModule('CONTRACTOR')||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','CONTRACT_MANAGER')">
                  <a (click)="navigateTo('/acreditacion/documentospendientes')">{{'general.acreditacion.docPend' |
                    translate}}</a>
                </li>
                <li
                  [hidden]="core.users.checkModule('CONTRACTOR')||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','CONTRACT_MANAGER')">
                  <a (click)="navigateTo('/acreditacion/documentosvencidos')">Documentos vencidos y por vencer
                    <!--<span class="etiquetaNuevo">Nuevo</span>-->
                  </a>

                </li>
                <li [hidden]="!core.users.checkModule('CONTRACTOR')">
                  <a (click)="navigateTo('/acreditacion/documentosvencidos')">Documentos vencidos y por vencer
                    <!--<span class="etiquetaNuevo">Nuevo</span>-->
                  </a>
                </li>
                <li [hidden]="subContract||!core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')">
                  <a (click)="navigateTo('/acreditacion/documentospendientes')">{{'general.acreditacion.docPend' |
                    translate}}</a>
                </li>
                <li
                  [hidden]="subContract||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','CONTRACT_MANAGER')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')||core.users.checkPermission('WORKS','GLOBAL','HSEQ')">
                  <a (click)="navigateTo('/acreditacion/invitacionesenviadas')">{{'general.acreditacion.invitacionesEnv'
                    | translate}}</a>
                </li>
                <li
                  [hidden]="!core.users.checkModule('CONTRACTOR')||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','CONTRACT_MANAGER')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')||core.users.checkPermission('WORKS','GLOBAL','HSEQ')">
                  <a (click)="navigateTo('/acreditacion/invitacionesrecibidas')">{{'general.acreditacion.invitacionesRec'
                    | translate}}</a>
                </li>
                <li
                  [hidden]="subContract||!core.users.checkSubModule('ACREDITATION','REVIEWERS')||core.users.checkModule('CONTRACTOR')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')">
                  <a (click)="navigateTo('/acreditacion/ajustes')">{{'general.ajustes.ajustes' | translate}}</a>
                </li>
                <li [hidden]="subContract&&core.users.checkModule('CONTRACTOR')">
                  <a (click)="navigateTo('/acreditacion/arbol')">{{'general.acreditacion.arbol' | translate}}</a>
                </li>

                <li [hidden]="(core.users.checkModule('CONTRACTOR'))">
                  <a (click)="navigateTo('/acreditacion/busqueda_empleados')">Busqueda de empleados y
                    vehículos <span class="etiquetaNuevo">Nuevo</span></a>
                </li>

                <li [hidden]="subContract&&core.users.checkModule('CONTRACTOR')">
                  <a (click)="navigateTo('/acreditacion/busqueda')">{{'general.acreditacion.busqueda' | translate}}</a>
                </li>
              </ul>
            </li>

            <!-- TODOS -->

            <li class="nav-item dropdown"
              [hidden]="true||!core.users.checkSubModule('ACREDITATION','GLOBAL')||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','SUPERVISOR_WEB')">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-map-alt"></i>
                </span>
                <span class="title">{{'general.seguimiento.seguimiento' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="navigateTo('/seguimiento/dashboard')">{{'general.seguimiento.dashboard' | translate}}</a>
                </li>
                <li>
                  <a (click)="navigateTo('/seguimiento/inspeccion')">{{'general.seguimiento.inspecciones' |
                    translate}}</a>
                </li>
                <li>
                  <a (click)="navigateTo('/seguimiento/inspeccion/all')">{{'general.seguimiento.detalleInsp' |
                    translate}}</a>
                </li>
                <li>
                  <a (click)="navigateTo('/seguimiento/eventos-sh')">{{'general.seguimiento.eventos' | translate}}</a>
                </li>
                <li>
                  <a (click)="navigateTo('/seguimiento/stopworks')">{{'general.seguimiento.stop' | translate}}</a>
                </li>
              </ul>
            </li>
            <!-- TODOS -->
            <!-- HSEQ -->
            <li [hidden]="true" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-alert"></i>
                </span>
                <span class="title">Stopwork</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="navigateTo('/stopwork/listastopwork')">Lista de Stopwork</a>
                </li>
                <li>
                  <a (click)="navigateTo('/stopwork/historicostopwork')">Historico Stopwork</a>
                </li>
              </ul>
            </li>
            <!-- CONTRATISTA -->
            <li [hidden]="!(core.users.checkModule('CONTRACTOR'))" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-envelope"></i>
                </span>
                <span class="title">{{'general.misContratos.misContratos' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <!--<li>
              <a (click)="navigateTo('/contratos/nuevocontrato')">Nuevo Contrato</a>
            </li>-->
                <li>
                  <a (click)="navigateTo('/contratos/listacontratos')">{{'contratos.listacontratos.titulo' |
                    translate}}</a>
                </li>
              </ul>
            </li>
            <!-- CONTRATISTA -->
            <li
              [hidden]="(!core.users.checkSubModule('ACREDITATION','GLOBAL')&&!(core.users.checkModule('CONTRACTOR')))||subContract"
              class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bag"></i>
                </span>
                <span class="title"
                  *ngIf="!core.users.checkModule('CONTRACTOR')">{{'general.misContrataciones.misContrataciones' |
                  translate}}</span>
                <span class="title" *ngIf="core.users.checkModule('CONTRACTOR')">{{'general.subContratos.subContratos' |
                  translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li *ngIf="core.users.checkModule('CONTRACTOR')">
                  <a (click)="navigateTo('/empresa_contratos/nuevocontrato')">{{'general.subContratos.nuevoSub' |
                    translate}}</a>
                </li>
                <li
                  [hidden]="core.users.checkModule('CONTRACTOR')||subContract||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')">
                  <a (click)="navigateTo('/empresa_contratos/nuevocontrato')">{{'general.misContrataciones.nuevoCon' |
                    translate}}</a>
                </li>
                <li *ngIf="!core.users.checkModule('CONTRACTOR')">
                  <a (click)="navigateTo('/empresa_contratos/listacontratos')">{{'general.misContrataciones.listaCon' |
                    translate}}</a>
                </li>
                <li *ngIf="core.users.checkModule('CONTRACTOR')">
                  <a (click)="navigateTo('/empresa_contratos/listacontratos')">{{'general.subContratos.listaSub' |
                    translate}}</a>
                </li>
              </ul>
            </li>
            <li [hidden]="!core.users.checkModule('EMPLOYEES')" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-user"></i>
                </span>
                <span class="title">{{'general.trabajadores.trabajadores' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a [hidden]="!core.users.checkPermission('EMPLOYEES','GLOBAL','CREATE')"
                    [routerLink]="['/trabajadores/nuevotrabajador']">{{'general.trabajadores.nuevoTrab' |
                    translate}}</a>
                </li>
                <li>
                  <a [hidden]="!core.users.checkPermission('EMPLOYEES','GLOBAL','VIEW')"
                    (click)="navigateTo('/trabajadores/listatrabajadores')"
                    style="cursor: pointer;">{{'general.trabajadores.listaTrab' | translate}}</a>
                </li>
              </ul>
            </li>
            <!-- CONTRATISTA -->
            <!-- CONTRATISTA -->
            <!--<li [hidden]="!core.users.checkModule('ACTIVES')" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-truck"></i>
                </span>
                <span class="title">{{'general.vehiculos.vehiculos' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a [hidden]="!core.users.checkPermission('ACTIVES','VEHICLES','CREATE')"
                    (click)="navigateTo('/vehiculos/nuevovehiculo')">{{'general.vehiculos.nuevoVeh' | translate}}</a>
                </li>
                <li>
                  <a [hidden]="!core.users.checkPermission('ACTIVES','VEHICLES','VIEW')"
                    (click)="navigateTo('/vehiculos/listavehiculos')"
                    style="cursor: pointer;">{{'general.vehiculos.listaVeh' | translate}}</a>
                </li>
              </ul>
            </li>-->
            <!-- TODOS -->
            <!-- TODOS -->
           <!-- <li [hidden]="core.users.checkSubModule('MAP_ROUTES','GLOBAL')" class="nav-item dropdown mrg-bottom-30">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-ruler-alt-2"></i>
                </span>
                <span class="title">{{'general.cuadrillas.cuadrillas' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu mrg-btn-30">
                <li [hidden]="core.users.checkSubModule('ACREDITATION','GLOBAL')">
                  <a (click)="navigateTo('/cuadrillas/crear')">Crear</a>
                </li>
                <li>
                  <a (click)="navigateTo('/cuadrillas/lista')">{{'general.cuadrillas.listaCuadrillas' | translate}}</a>
                </li>
              </ul>
            </li>-->

            <li class="nav-item dropdown"
              [hidden]="!core.users.checkSubModule('ACREDITATION','GLOBAL')||core.users.checkPermission('ACREDITATION','GLOBAL','VISUALIZER')||core.users.checkPermission('ACREDITATION','GLOBAL','SUPERVISOR_WEB')">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-direction-alt"></i>
                </span>
                <span class="title">{{'general.entrance.entrance' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="navigateTo('/entrance/employees')">{{'general.entrance.empleados' | translate}}</a>
                </li>
                <li>
                  <a (click)="navigateTo('/entrance/vehicles')">{{'general.entrance.vehiculos' | translate}}</a>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown" [hidden]="!core.users.checkSubModule('MAP_ROUTES','GLOBAL')">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-map-alt"></i>
                </span>
                <span class="title">Rutas</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="navigateTo('/map-routes/new')">Nueva ruta</a>
                </li>
                <li>
                  <a (click)="navigateTo('/map-routes/list')">Lista de rutas</a>
                </li>
              </ul>
            </li>

           <!-- <li class="nav-item dropdown" [hidden]="!core.users.checkPermission('CUSTOM_PLAN','GLOBAL','ADMIN')">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bookmark-alt"></i>
                </span>
                <span class="title">Planes</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="" [routerLink]="['/planes/crearplan']">Crear plantilla de plan</a>
                </li>
                <li>
                  <a href="" [routerLink]="['/planes/listaplanes']">Lista de Plantillas</a>
                </li>
                <li>
                  <a href="" [routerLink]="['/planes/resume','admin']">Lista de Planes</a>
                </li>
              </ul>
            </li>-->
            <li class="nav-item dropdown" [hidden]="!(this.core.users.user?.isReviewer)">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bookmark-alt"></i>
                </span>
                <span class="title">Revisión de planes</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="" [routerLink]="['/planes/resume','reviewer']">Mis Planes</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bookmark-alt"></i>
                </span>
                <span class="title">Planes Asignados</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="" [routerLink]="['/planes/resume','assigned']">Mis Planes</a>
                </li>
              </ul>
            </li>

            <!--<li *ngIf="core.qa" class="nav-item dropdown">
              <a [routerLink]="['/courses/all']">
                <span class="icon-holder">
                  <i class="ti-medall"></i>
                </span>
                <span class="title">Capacitaciones</span>
              </a>
            </li>-->

            <li class="nav-item dropdown">
              <a [routerLink]="['/historial-de-actividad']">
                <span class="icon-holder">
                  <i class="ti-bell"></i>
                </span>
                <span class="title">Historial/Actividad</span>
              </a>
            </li>



            <li [hidden]="!(core.country=='morocco')" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-stamp"></i>
                </span>
                <span class="title">{{'general.work-permits.permisos' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li [hidden]="core.users.checkSubModule('ACREDITATION','GLOBAL')">
                  <a href="" [routerLink]="['/work-permits/crear']">{{'general.work-permits.nuevoPermiso' |
                    translate}}</a>
                </li>
                <li>
                  <a href="" [routerLink]="['/work-permits/lista']">{{'general.work-permits.listaPermisos' |
                    translate}}</a>
                </li>
              </ul>
            </li>

            <!-- CONTRATISTA -->
            <!-- CONTRATISTA -->
            <li [hidden]="!(core.users.checkModule('CONTRACTOR'))" class="nav-item dropdown mrg-bottom-30">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-file"></i>
                </span>
                <span class="title">{{'general.docEmpresa.docEmpresa' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu mrg-btn-30">
                <li>
                  <a (click)="navigateTo('/documentoscontratista/documentos')">{{'general.docEmpresa.listaDocEmpresa' |
                    translate}}</a>
                </li>
              </ul>
            </li>
            <li [hidden]="true" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-bar-chart"></i>
                </span>
                <span class="title">Kpi</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a [routerLink]="['/kpi/global']">Global</a>
                </li>
              </ul>
            </li>

            <li [hidden]="!core.users.checkSubModule('HH','GLOBAL')" class="nav-item dropdown">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-timer"></i>
                </span>
                <span class="title">Horas Hombre</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">

                <li>
                  <a (click)="navigateTo('/man-hours/')">Gestión de HH</a>
                </li>

                <!--<li [hidden]="!core.users.checkPermission('HH','GLOBAL','LOG')">
                        <a href="https://app.cluvio.com/dashboards/9x36-z2l2-x67r" target="_blank">KPIs</a>
                      </li>-->

                <!--<li [hidden]="!(core.users.checkPermission('HH','GLOBAL','EDIT')&&!core.users.checkPermission('HH','GLOBAL','LOG'))">
                        <a (click)="openYouTubeModalHHContractor()">Tutorial</a>
                      </li>
                      
                      <li [hidden]="!(core.users.checkPermission('HH','GLOBAL','REVIEW')&&!core.users.checkPermission('HH','GLOBAL','LOG'))">
                        <a (click)="openYouTubeModalHHReviewer()">Tutorial</a>
                      </li>
                      
                      <li [hidden]="!core.users.checkPermission('HH','GLOBAL','LOG')">
                        <a (click)="openYouTubeModalHHAdmin()">Tutorial</a>
                      </li>-->



              </ul>

            </li>





            <!--<li clas="nav-item">
                    <a class="mrg-top-30" style="cursor: pointer;" (click)="openYouTubeModal()">
                      <span class="icon-holder">
                        <i class="ti-control-play"></i>
                      </span>
                      <span class="title">Video Tutorial</span>
                    </a>
                  </li>-->
            <!---------------------------SOLO VISIBLE PARA WISESOLUTIONS----------------------->
            <li class="nav-item dropdown"
              [hidden]="!(core.users.checkSubModule('ACREDITATION','REVIEWERS')||core.users.checkSubModule('SETTINGS','USERS'))||core.users.checkPermission('ACREDITATION','GLOBAL','SUPERVISOR_WEB')||core.users.checkPermission('ACREDITATION','GLOBAL','REVISOR_WEB')">
              <a class="dropdown-toggle" href="javascript:void(0);">
                <span class="icon-holder">
                  <i class="ti-settings"></i>
                </span>
                <span class="title">{{'general.ajustes.ajustes' | translate}}</span>
                <span class="arrow">
                  <i class="ti-angle-right"></i>
                </span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a [hidden]="false" [routerLink]="['/ajustes/usuarios']">{{'general.ajustes.usuarios' |
                    translate}}</a>
                </li>
                <li>
                  <a [hidden]="!core.users.checkSubModule('SETTINGS','COMPANIES') && !core.users.checkModule('SETTINGS')"
                    [routerLink]="['/ajustes/empresas']">{{'general.ajustes.empresas' | translate}}</a>
                </li>
                <li>
                  <a [hidden]="!core.users.checkSubModule('SETTINGS','PROFILES') && !core.users.checkModule('SETTINGS')"
                    [routerLink]="['/ajustes/permisos']">{{'general.ajustes.permisos' | translate}}</a>
                </li>
                <li>
                  <a [hidden]="!core.users.checkSubModule('SETTINGS','PARAMETERS') && !core.users.checkModule('SETTINGS')"
                    [routerLink]="['/ajustes/parametros']">{{'general.ajustes.parametros' | translate}}</a>
                </li>
                <li>
                  <a  [hidden]="!(core.users.checkPermission('REPO','GLOBAL','GC') || core.users.checkPermission('REPO','GLOBAL','HSEQ'))"
                  [routerLink]="['/reposited-document/list']">Repositorio Documentos</a>
                </li>
              </ul>
            </li>

            <!--   <li clas="nav-item">
                    <a style="cursor: pointer;" (click)="openXl(tutoriales)">
                      <span class="icon-holder">
                        <i class="ti-control-play"></i>
                      </span>
                      <span class="title">Tutoriales</span>
                    </a>
                  </li> -->


            <!--<li clas="nav-item">
                    <a style="cursor: pointer;" (click)="openXl(tutoriales)">
                      <span class="icon-holder">
                        <i class="ti-control-play"></i>
                      </span>
                      <span class="title">{{'general.tutoriales' | translate}}</span>
                    </a>
                  </li>-->
            <!---------------------------SOLO VISIBLE PARA WISESOLUTIONS----------------------->
            <!-- GESTOR DE CONTRATO -->

            <li clas="nav-item" routerLinkActive="active">
              <a class="mrg-top-30" href="" [routerLink]="['/ayuda']">
                <span class="icon-holder">
                  <i class="ti-help"></i>
                </span>
                <span class="title">Ayuda</span>
              </a>
            </li>

            <!--<li clas="nav-item" routerLinkActive="active">
                    <a (click)="actualizacionModal()">
                      <span class="icon-holder">
                        <i class="ti-star"></i>
                      </span>
                      <span class="title">Nueva actualización</span>
                    </a>
                  </li>-->
          </ul>
        </div>
      </div>
      <!-- Side Nav END -->
      <!-- Page Container START -->
      <div class="page-container">
        <!-- Top Nav START -->
        <div class="header navbar">
          <div class="header-container">
            <ul class="nav-left">
              <li>
                <a class="side-nav-toggle" href="javascript:void(0);"
                  (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                  <i class="ti-view-grid"></i>
                </a>
              </li>
            </ul>

            <ul class="nav-right">


              <li class="notifications dropdown">
                <a href="" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="ti-world"></i>
                </a>
                <ul class="dropdown-menu idiomas">
                  <ul class="list-info overflow-y-auto relative scrollable">
                    <li class="info">
                      <a (click)="useLanguage('en-US')" class="d-flex align-items-center justify-content-start">
                        <img class="thumb-img" src="assets/images/banderas/eeuu.png" alt="">
                        <div>
                          <span class="title">
                            <span class="font-size-14 text-semibold">{{'idiomas.ingles' | translate}}</span>
                          </span>
                        </div>
                      </a>
                    </li>
                    <li class="info">
                      <a (click)="useLanguage('es-CL')" class="d-flex align-items-center justify-content-start">
                        <img class="thumb-img" src="assets/images/banderas/espana.png" alt="">
                        <div>
                          <span class="title">
                            <span class="font-size-14 text-semibold">{{'idiomas.espanol' | translate}}</span>
                          </span>
                        </div>
                      </a>
                    </li>
                    <li class="info">
                      <a (click)="useLanguage('it-IT')" class="d-flex align-items-center justify-content-start">
                        <img class="thumb-img" src="assets/images/banderas/italia.png" alt="">
                        <div>
                          <span class="title">
                            <span class="font-size-14 text-semibold">{{'idiomas.italiano' | translate}}</span>
                          </span>
                        </div>
                      </a>
                    </li>
                    <li class="info">
                      <a (click)="useLanguage('fr-FR')" class="d-flex align-items-center justify-content-start">
                        <img class="thumb-img" src="assets/images/banderas/francia.png" alt="">
                        <div>
                          <span class="title">
                            <span class="font-size-14 text-semibold">{{'idiomas.frances' | translate}}</span>
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </ul>
              </li>



            

              <li [hidden]="true" class="notifications dropdown">
                <span class="counter">2</span>
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i class="ti-bell"></i>
                </a>
                <ul class="dropdown-menu ">
                  <li class="notice-header">
                    <i class="ti-bell pdd-right-10"></i>
                    <span>Notificaciones</span>
                  </li>
                  <ul [perfectScrollbar] class="list-info overflow-y-auto relative scrollable">
                    <li>
                      <a>
                        <span class="thumb-img bg-info">
                          <span class="text-white"><i class="ti ti-file"></i></span>
                        </span>
                        <div class="info">
                          <span class="title">
                            <span class="font-size-14 text-gray lowercase"><b class="normal text-black">Juanito perez</b>&nbsp;cambio el estado de un documento a&nbsp;<b class="normal text-danger">Rechazado</b></span>
                          </span>
                          <span class="sub-title">Hace 5 minutos</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a>
                        <span class="thumb-img bg-info">
                          <span class="text-white"><i class="ti ti-file"></i></span>
                        </span>
                        <div class="info">
                          <span class="title">
                            <span class="font-size-14 text-gray lowercase"><b class="normal text-black">Juanito perez</b>&nbsp;cambio el estado de un documento a&nbsp;<b class="normal text-success">Aprobado</b></span>
                          </span>
                          <span class="sub-title">Hace 5 minutos</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <li class="notice-footer">
                    <span>
                      <a class="text-gray" [routerLink]="['/notificaciones']">
                        Ver todas las notificaciones
                        <i class="ei-right-chevron pdd-left-5 font-size-10"></i>
                      </a>
                    </span>
                  </li>
                </ul>
              </li>

              <li class="user-profile dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <img class="profile-img img-fluid"
                    [src]="(core.users?.user?.photo)!=''?core.getFile(core.users.user.photo):'assets/images/extras/fotoHolder.png'">
                  <div class="user-info">
                    <span class="name pdd-right-5">{{ core.users?.user?.name }}</span>
                    <i class="ti-angle-down font-size-10"></i>
                  </div>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="" [routerLink]="['/configuracion']">
                      <i class="ti-user pdd-right-10"></i>
                      <span>{{'ajustes.permisos.perfilesusuarios.perfil' | translate}}</span>
                    </a>
                  </li>
                  <li *ngIf="(core.users.checkModule('CONTRACTOR'))">
                    <a href="" [routerLink]="['/mi-empresa']">
                      <i class="ti-settings pdd-right-10"></i>
                      <span>{{'general.empresa' | translate}}</span>
                    </a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a (click)="logout()">
                      <i class="ti-power-off pdd-right-10"></i>
                      <span>{{'general.cerrar' | translate}}</span>
                    </a>
                  </li>
                </ul>

              </li>
              <li [hidden]="generalDocs.length==0&&gcDocs.length==0">
                <a class="side-panel-toggle" href="javascript:void(0);"
                  (click)="app.layout.sidePanelOpen = !app.layout.sidePanelOpen">
                  <i class="ti-folder"></i>
                  <span class="etiquetaNuevo">Nuevo</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Top Nav END -->
        <!-- Side Panel START -->
        <div class="side-panel" [ngClass]="{'side-panel-open' : app.layout.sidePanelOpen}">
          <div class="side-panel-wrapper bg-white">
            <div class="w-100 headerLateral">
              <div class="w-80 tituloDocumento">
                <div class="subTituloDocumento"><i class="ti-folder"></i> Documentos generales.</div>
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link" [class.active]="docTabs[0]" (click)="docTabs[0]=true;docTabs[1]=false;">HSE</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [class.active]="docTabs[1]" (click)="docTabs[0]=false;docTabs[1]=true;">GC</a>
                  </li>
                </ul>
                <!--<span class="etiquetaNuevo">Nuevo</span>-->
              </div>
              <div class="w-20 text-right pdd-right-20">
                <a class="side-panel-toggle" href="javascript:void(0);"
                  (click)="app.layout.sidePanelOpen = !app.layout.sidePanelOpen">
                  <i class="ti-close" style="font-weight: 600;"></i>
                </a>
              </div>
            </div>
            <div [hidden]="!docTabs[0]" class="detalleDocumentos overflow-y-auto scrollable relative">
              <ul class="list-info">
                <li *ngFor="let doc of generalDocs">
                  <a target="_blank" [href]="core.getFile(doc.url)">
                    <span class="thumb-img bg-info">
                      <span class="text-white"><i class="ti-file"></i></span>
                    </span>
                    <div class="info">
                      <span class="title">
                        <span class="font-size-14 text-semibold">{{doc.name}}</span>
                      </span>
                      <span class="sub-title">{{doc.description}}</span>
                      <span class="sub-title"><b>Versión:</b> {{doc.updated_at | date:'dd/MM/y'}}</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div [hidden]="!docTabs[1]" class="detalleDocumentos overflow-y-auto scrollable relative">
              <ul class="list-info">
                <li *ngFor="let doc of gcDocs">
                  <a target="_blank" [href]="core.getFile(doc.url)">
                    <span class="thumb-img bg-info">
                      <span class="text-white"><i class="ti-file"></i></span>
                    </span>
                    <div class="info">
                      <span class="title">
                        <span class="font-size-14 text-semibold">{{doc.name}}</span>
                      </span>
                      <span class="sub-title">{{doc.description}}</span>
                      <span class="sub-title"><b>Versión:</b> {{doc.updated_at | date:'dd/MM/y'}}</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Side Panel END -->
        <!-- Content Wrapper START -->
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
        <!-- Content Wrapper END -->
        <!-- Footer START -->
        <footer class="content-footer">
          <div class="footer">
            <div class="copyright">
              <span class="go-right">
                <span>WiseSolutions Copyright © 2020</span>
              </span>
            </div>
          </div>
        </footer>
        <!-- Footer END -->
      </div>
      <!-- Page Container END -->
    </div>
  </div>
</div>
<div bsModal #youtubeModal="bs-modal" class="modal fade modal-fs" id="modal-fs">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background: rgba(255, 255, 255, 0.7);">
      <div class="modal-body">
        <div class="vertical-align text-center">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-12 mr-auto ml-auto">
                  <div class="pdd-btm-50">
                    <div class="page-title">
                      <h4 customTituloModal class="mrg-btm-20">Bienvenido a WiseFollow</h4>
                      <h4 customSubtituloModal>Antes de comenzar, le recomendamos que vea este tutorial el cual le
                        explicara el funcionamiento del sistema.</h4>
                      <h4 customSubtituloModal>Puedes volver a ver este video desde el menú en caso de dudas</h4>
                    </div>
                      <iframe *ngIf="openedModal" width="852" height="480" src="https://www.youtube.com/embed/y5PY8rg2moY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="modal-close" (click)="closeYouTubeModal()">
          <i class="ti-close">
          </i>
        </a>
      </div>
    </div>
  </div>
</div>

<div bsModal #youtubeModalHHAdmin="bs-modal" class="modal fade modal-fs" id="modal-fs" style="top: 10%">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background: rgba(255, 255, 255, 0.7);">
      <div class="modal-body">
        <div class="vertical-align text-center">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-12 mr-auto ml-auto">
                  <div class="pdd-btm-50">
                    <div class="page-title">
                      <h4 customTituloModal class="mrg-btm-20">Video Tutorial de Módulo de Horas Hombre</h4>
                      <h4 customSubtituloModal>Antes de comenzar, le recomendamos que vea este tutorial el cual le
                        explicara el funcionamiento del módulo.</h4>
                      <h4 customSubtituloModal>Puedes volver a ver este video desde el menú en caso de dudas</h4>
                    </div>
                    <iframe *ngIf="openedModal"
                      src="https://drive.google.com/file/d/1Q3xS3JZkdfe9Q-aK54_OP7v3ZlQDaNGV/preview" width="852"
                      height="480"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="modal-close" (click)="closeYouTubeModalHHAdmin()">
          <i class="ti-close"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<div bsModal #youtubeModalHHContractor="bs-modal" class="modal fade modal-fs" id="modal-fs" style="top: 10%">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background: rgba(255, 255, 255, 0.7);">
      <div class="modal-body">
        <div class="vertical-align text-center">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-12 mr-auto ml-auto">
                  <div class="pdd-btm-50">
                    <div class="page-title">
                      <h4 customTituloModal class="mrg-btm-20">Video Tutorial de Módulo de Horas Hombre</h4>
                      <h4 customSubtituloModal>Antes de comenzar, le recomendamos que vea este tutorial el cual le
                        explicara el funcionamiento del módulo.</h4>
                      <h4 customSubtituloModal>Puedes volver a ver este video desde el menú en caso de dudas</h4>
                    </div>
                    <iframe *ngIf="openedModal"
                      src="https://drive.google.com/file/d/1-9x3UAfKIEjUMbAXAaJUu8EREXWk63fz/preview" width="852"
                      height="480"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="modal-close" (click)="closeYouTubeModalHHContractor()">
          <i class="ti-close"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<div bsModal #youtubeModalHHReviewer="bs-modal" class="modal fade modal-fs" id="modal-fs" style="top: 10%">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="background: rgba(255, 255, 255, 0.7);">
      <div class="modal-body">
        <div class="vertical-align text-center">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-12 mr-auto ml-auto">
                  <div class="pdd-btm-50">
                    <div class="page-title">
                      <h4 customTituloModal class="mrg-btm-20">Video Tutorial de Módulo de Horas Hombre</h4>
                      <h4 customSubtituloModal>Antes de comenzar, le recomendamos que vea este tutorial el cual le
                        explicara el funcionamiento del módulo.</h4>
                      <h4 customSubtituloModal>Puedes volver a ver este video desde el menú en caso de dudas</h4>
                    </div>
                    <iframe *ngIf="openedModal"
                      src="https://drive.google.com/file/d/1-qJhEhd6EYHOuJJrmoek0qeO3zj_Frw5/preview" width="852"
                      height="480"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="modal-close" (click)="closeYouTubeModalHHReviewer()">
          <i class="ti-close"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #tutoriales let-modal>
  <div class="modal-header no-pdd-btm align-items-center">
    <i class="ti-control-play font-size-30"></i>
    <h4 class="card-title font-size-25 padding-10"><b>Tutoriales</b></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="accordion-1" class="accordion panel-group" role="tablist" aria-multiselectable="true">
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingOne">
          <h4 class="panel-title">
            <a data-toggle="collapse" data-parent="#accordion-1" href="#collapseOne">
              <span>Tutorial 1: Invitación y Creación Contratista</span>
              <i class="icon ti-arrow-circle-down"></i>
            </a>
          </h4>
        </div>
        <div id="collapseOne" class="collapse panel-collapse text-center pdd-top-10">
          <div class="panel-body">
            <iframe src="https://drive.google.com/file/d/19sOCHRfaQC54yzzOtxyrVTvgJ_y1uotN/preview" width="100%"
              height="400"></iframe>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingTwo">
          <h4 class="panel-title">
            <a class="collapsed" data-toggle="collapse" data-parent="#accordion-1" href="#collapseTwo">
              <span>Tutorial 2: Carga documentos de empresa</span>
              <i class="icon ti-arrow-circle-down"></i>
            </a>
          </h4>
        </div>
        <div id="collapseTwo" class="collapse panel-collapse text-center" role="tabpanel">
          <div class="panel-body">
            <div class="panel-body">
              <iframe src="https://drive.google.com/file/d/1dCzjHFadVZkYYiDe7jHpvFd7pVG1P7uL/preview" width="100%"
                height="400"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingThree">
          <h4 class="panel-title">
            <a class="collapsed" data-toggle="collapse" data-parent="#accordion-1" href="#collapseThree">
              <span>Tutorial 3: Crear Trabajadores y Vehículos</span>
              <i class="icon ti-arrow-circle-down"></i>
            </a>
          </h4>
        </div>
        <div id="collapseThree" class="collapse panel-collapse text-center" role="tabpanel">
          <div class="panel-body">
            <div class="panel-body">
              <iframe src="https://drive.google.com/file/d/1hq_8KU007PqEWQjOt8OHvqpwE8iZpAky/preview" width="100%"
                height="400"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingThree">
          <h4 class="panel-title">
            <a class="collapsed" data-toggle="collapse" data-parent="#accordion-1" href="#collapseFour">
              <span>Tutorial 4: Creación de contratos</span>
              <i class="icon ti-arrow-circle-down"></i>
            </a>
          </h4>
        </div>
        <div id="collapseFour" class="collapse panel-collapse text-center" role="tabpanel">
          <div class="panel-body">
            <div class="panel-body">
              <iframe src="https://drive.google.com/file/d/1TKCIQ8mFinW42dgcLjuSEmOBLu-Sbxe6/preview" width="100%"
                height="400"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingThree">
          <h4 class="panel-title">
            <a class="collapsed" data-toggle="collapse" data-parent="#accordion-1" href="#collapseFive">
              <span>Tutorial 5: Asignar trabajadores y vehículos a contrato</span>
              <i class="icon ti-arrow-circle-down"></i>
            </a>
          </h4>
        </div>
        <div id="collapseFive" class="collapse panel-collapse text-center" role="tabpanel">
          <div class="panel-body">
            <div class="panel-body">
              <iframe src="https://drive.google.com/file/d/1HQMxKZYmBKgW4R3-0ABvUTsPp9rjDtc6/preview" width="100%"
                height="400"></iframe>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>


<ng-template #content let-modal>
  <div class="tab-content" style="width: 100%;">
    <div role="tabpanel" class="tab-pane active" id="info-1">
      <div class="">
        <div class="w-100"
          style="height: 350px; background-image: url('assets/images/ModalNuevosCamposGestcon.png'); background-size: cover; border-radius: 5px 5px 0px 0px;">
          <img class="w-50" src="" alt="">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
          style="position: absolute; top: 20px; right: 20px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pdd-top-25">
        <h5 style="text-align: center; font-size: .9rem; color: #b9b9b9; margin-bottom: 0;">Próxima actualización</h5>
        <h2 style="text-align: center; color: #2e9df6; margin-bottom: 20px;">Cambios en campos de formularios</h2>
        <p style="text-align: center;">En esta nueva actualización se incluirá a WiseFollow campos requeridos por gestión de contratistas. Estos campos los vamos a ver presentes específicamente en los procesos de creación de trabajadores, vehículos y contratos.</p>
        <p style="text-align: center;">A su vez, también los documentos “Contrato de trabajo”, ”Anexo Contrato de Trabajo”, ”Documentos F30-1”, “Certificado de cotizaciones previsionales” y “Finiquitos” ya no van a ser almacenados en la plataforma WiseFollow solamente se registrará la carga de estos documentos; Todo lo relacionado a revisión y visualización de estos documentos será en la plataforma de Gestcon.</p>
        <p style="text-align: center;">Los cambios serán incorporados a partir del <b>12 de Noviembre del 2021</b>.</p>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade" id="info-2">
      <div class="">
        <div class="w-100"
          style="height: 350px; background-image: url('assets/images/ModalReportes.png'); background-size: cover; border-radius: 5px 5px 0px 0px;">
          <img class="w-50" src="" alt="">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
          style="position: absolute; top: 20px; right: 20px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pdd-top-25">
        <h5 style="text-align: center; font-size: .9rem; color: #b9b9b9; margin-bottom: 0;">Nueva actualización</h5>
        <h2 style="text-align: center; color: #2e9df6; margin-bottom: 20px;">Reportes de trabajadores y vehículos</h2>
        <p style="text-align: center;">Se ha agregado a la plataforma WiseFollow la capacidad de exportar diferentes
          tipos de reportes respectivos a cada sección (Trabajadores y Vehículos). Para descargar y hacer uso de estos
          reportes, deberas hacer click en el botón de "Reporte" que encontraras en la parte superior derecha de varias
          secciones de nuestra plataforma.</p>
        <p style="text-align: center;">Esperamos que esta actualización te ayude a visualizar y utilizar tu información
          de una manera fácil y clara.</p>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade" id="info-3">
      <div class="">
        <div class="w-100"
          style="height: 350px; background-image: url('assets/images/modalDocumentosNuevos.png'); background-size: cover; border-radius: 5px 5px 0px 0px;">
          <img class="w-50" src="" alt="">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
          style="position: absolute; top: 20px; right: 20px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pdd-top-25">
        <h5 style="text-align: center; font-size: .9rem; color: #b9b9b9; margin-bottom: 0;">Nueva actualización</h5>
        <h2 style="text-align: center; color: #2e9df6; margin-bottom: 20px;">Documentos y plantillas generales</h2>
        <p style="text-align: center;">Hemos agregado al sistema, en la parte superior derecha, un boton que te
          permitirá descargar los documentos de salud y seguridad de Enel que se requieren para tu proceso de
          acreditación, de esta manera, hacemos tu acreditación mas facil y rápida</p>
        <p style="text-align: center;">Esperamos que esta actualización haga tu proceso de acreditación mas sencillo y
          rápido</p>
      </div>
    </div>
  </div>
  <!--<div class="pill-primary" style="padding: 0px 0px 20px 0px;">
    <ul class="nav nav-pills justify-content-center" role="tablist">
      <li class="nav-item">
        <a href="#info-1" class="nav-link active" role="tab" data-toggle="tab">Cambios en documentos</a>
      </li>
      <li class="nav-item">
        <a href="#info-2" class="nav-link" role="tab" data-toggle="tab">Reportes</a>
      </li>
      <li class="nav-item">
        <a href="#info-3" class="nav-link" role="tab" data-toggle="tab">Documentos</a>
      </li>
    </ul>
  </div>-->
  <div class="" style="text-align: center; margin-bottom: 20px;">
    <button type="button" class="btn btn-info" (click)="modal.close('Close click')"
      style="width: 20%;">Comenzar</button>
  </div>

</ng-template>