import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataTablesResponse } from '@models/datatables-response';
import swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AcreditationService {
  baseUrl = environment.baseUrl;

  current_employee: any = null;
  current_vehicle: any = null;
  current_company_doc: any = null;
  current_branch_doc: any = null;
  current_work: any = null;

  constructor(private http: HttpClient) { }

  getDatatable(entity, dataTablesParameters, data?) {
    if (data) {
      dataTablesParameters['data'] = data;
    }
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/api/${entity}`, dataTablesParameters, {}
    )
  }

  all(entity) {
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }

  get(entity) {
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }

  create(entity, obj) {
    return this.http.post<any>(`${this.baseUrl}/api/${entity}`, obj);
  }

  edit(entity, obj) {
    return this.http.put<any>(`${this.baseUrl}/api/${entity}`, obj);
  }

  publish(entity, obj) {
    return this.http.put<any>(`${this.baseUrl}/api/${entity}`, obj);
  }

  

  erase(entity, id) {
    return this.http.delete<any>(`${this.baseUrl}/api/${entity}/${id}`);
  }

  isOutOfTime(text_date) {
    let current_date: any = new Date();
    let other_date: any = new Date(text_date);
    return (other_date.getTime() < current_date.getTime());
  }
  showObs(doc) {
    let text = 'No hay observaciones para el documento.';
    let from = '';

    if(doc.rejected_by!= '' && doc.rejected_by != null){
      from = 'Rechazado por: '+doc.rejected_by

    }
    if (doc.obs != '' && doc.obs != null) {
      text = doc.obs;
    }
    swal(
      {
        type: 'info',
        title: 'Observaciones',
        html: text+'<br>' +from,
     

      }
    );
  }
}
