import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CoreService } from '@core/core.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private core: CoreService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.core.users.logout();
                location.reload(true);
            }

            //const error = err.error.message || err.statusText;

            let errArr = [];

            if(err){
                if(typeof err.error !=='undefined'){
                let errors = JSON.parse(err.error.errors);
                
                for (let prop in errors) {
                    for(let i=0;i<errors[prop].length;i++){
                        errArr.push(errors[prop][i]);
                    }
                }
                }
            }

            return throwError(errArr);
        }))
    }
}
