import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataTablesResponse } from '@models/datatables-response';

@Injectable({
  providedIn: "root"
})
export class PlanService {
  baseUrl = environment.baseUrl;

  lang = "es";

  months = {
    es: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  };

  day_short_name = {
    es:[
      'D',
      'L',
      'M',
      'X',
      'J',
      'V',
      'S'
    ],
    en:[
      'U',
      'M',
      'T',
      'W',
      'R',
      'F',
      'S'
    ]
  }

  user_mode:any = '1';
  current_action_user_id:any = null;

  constructor(private http: HttpClient) {}

  getDatatable(entity, dataTablesParameters) {
    return this.http.post<DataTablesResponse>(
      `${this.baseUrl}/api/${entity}`,
      dataTablesParameters,
      {}
    );
  }

  all(entity) {
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }

  get(entity) {
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }

  create(entity, obj) {
    return this.http.post<any>(`${this.baseUrl}/api/${entity}`, obj);
  }

  edit(entity, obj) {
    return this.http.put<any>(`${this.baseUrl}/api/${entity}`, obj);
  }

  erase(entity, id) {
    return this.http.delete<any>(`${this.baseUrl}/api/${entity}/${id}`);
  }
  isOutOfTime(text_date) {
    let current_date: any = new Date();
    let other_date: any = new Date(text_date);
    return other_date.getTime() < current_date.getTime();
  }

  cal_days_in_month(month, year) {
    return new Date(year, month, 0).getDate();
  }

  get_month_name(month) {
    return this.months[this.lang][month];
  }

  leap_year(year)
  {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  year_width(year, day_ratio){
    let isLeap = this.leap_year(year);
    return (day_ratio  *  365) + ((isLeap)?day_ratio:0);
  }

  get_current_year_months(year, day_ratio) {
    let months = [];
    for (let i in this.months[this.lang]) {
      let month = {
        name: this.months[this.lang][i],
        days: this.cal_days_in_month(parseInt(i)+1, year),
        width: (day_ratio * this.cal_days_in_month(parseInt(i)+1, year))
      };
      months.push(month);
    }
    return months;
  }
  get_current_year_weeks(year, day_ratio) {
    let weeks = [];
    let isLeap = this.leap_year(year);
    for (let i=1;i<=52;i++) {
      let week = {
        name: i,
        width: day_ratio * 7
      };
      if(i==52){
        week.width = (day_ratio * 8)  + (isLeap?day_ratio:0);
      }
      weeks.push(week);
    }
    return weeks;
  }

  add_days(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  get_current_year_namedays(year, day_ratio){
    let namedays = [];
    let start_date_of_year = new Date();
    start_date_of_year.setFullYear(year);
    start_date_of_year.setMonth(0);
    start_date_of_year.setDate(1);
    start_date_of_year.setHours(0,0,0,0);

    let end_date_of_year = new Date();
    end_date_of_year.setFullYear(year);
    end_date_of_year.setMonth(11);
    end_date_of_year.setDate(31);
    end_date_of_year.setHours(23,59,59,59);


    for(let idate = start_date_of_year; idate.getTime()<=end_date_of_year.getTime();idate = this.add_days(idate,1)) {
      let day_num = idate.getDay();
      let date_number = ''+ ((idate.getDate() > 9) ? idate.getDate() : '0' + idate.getDate());

      let nameday = {
        name:this.day_short_name[this.lang][day_num],
        date_number:date_number,
        title: idate.getTime(),
        width: day_ratio,
        idate: idate
      }

      namedays.push(nameday);
    }
    return namedays;
  }

  get_processed_day(instances,day: Date){

    let response = {
      instance: null,
      show: false,
      clases: ['action_plan_cal_block', 'day-w']
    }

    const ds = new Date();
    ds.setFullYear(day.getFullYear());
    ds.setMonth(day.getMonth());
    ds.setDate(day.getDate());
    ds.setHours(0,0,0,0);

    /*const de = new Date();
    de.setFullYear(day.getFullYear());
    de.setMonth(day.getMonth());
    de.setDate(day.getDate());
    de.setHours(23,59,59,59);*/

    const cur = new Date();
    cur.setHours(0,0,0,0);

    for(let instance of instances){

      const ids = new Date(instance.start_date*1000);
      ids.setHours(0,0,0,0);

      const ide = new Date(instance.end_date*1000);
      ide.setHours(0,0,0,0);

      if(
        (
          ds.getTime() >=  ids.getTime()
          &&
          ds.getTime() <= ide.getTime()
        )
        ||
        (
          ds.getTime() ==  ids.getTime()
          &&
          ds.getTime() == ide.getTime()
        )
      ){

        //Calculo de Estado
        let cur_status = 'cal_bar_pending';
        //Fin de Calculo de Estado

        let have_pointer = 'have_pointer';

        if(this.user_mode=='1'){
            let valid_to_upload = false;

            if((typeof instance.userInstance !=='undefined')&&(instance.userInstance != null)){
                instance.status = instance.userInstance.status;
                instance.progress = instance.userInstance.progress;
                if(instance.progress == null || instance.progress ==''){
                  instance.progress = 0;
                }
                if(instance.userInstance.user_id==this.current_action_user_id){
                    valid_to_upload = true;
                }
                if(instance.userInstance.reviewers.some(reviewer=>reviewer.user_id==this.current_action_user_id)){
                  valid_to_upload = true;
                  if(instance.progress==0){
                    valid_to_upload = false;
                  }
              }
            }
            if(!valid_to_upload){
                have_pointer = '';
            }
        }

        if(
            (instance.progress==0)
            &&
            (instance.end_date*1000)>cur.getTime()
        ){
            cur_status = 'cal_bar_pending';

        }

        if(
            (instance.progress==0)
            &&
            (instance.end_date*1000)<=cur.getTime()
        ){
            cur_status = 'cal_bar_due';
        }

        if(
            (instance.progress==1)
        ){
            cur_status = 'cal_bar_process';
        }

        if(
            (instance.progress==2)
        ){
            cur_status = 'cal_bar_ok';
        }

        if(
            (instance.progress==3)
        ){
            cur_status = 'cal_bar_reject';
        }


        if(have_pointer!='')
          response.clases.push(have_pointer);

        response.clases.push(cur_status);
        response.instance = instance;
        response.show = true;
      }
    }

    return response;
  }

}
