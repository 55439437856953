import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '@core/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, public core: CoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {

      this.router.navigate(['authentication/sign-in'],{ queryParams: { returnUrl: state.url }});
      return false;
    }else{

      console.log(state.url);

      if(this.core.users.checkSubModule('ACREDITATION','GLOBAL')&&state.url=='/dashboard'){
        this.router.navigate(['branches/map']);
        return false;
      }
    }
    return true;

  }
}
