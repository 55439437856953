import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '@core/core.service';
import { environment } from '@environments/environment'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private core: CoreService, private translate: TranslateService) {
    translate.setDefaultLang(this.core.lang);
    if (environment.production) {
      console.log = function () { };
    }
  }

}
