import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataTablesResponse } from '@models/datatables-response';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  baseUrl = environment.baseUrl;
  company:any;
  public current_company_id:any=1;
  constructor(private http: HttpClient) {}
  dataTable(dataTablesParameters){
    return this.http
			.post<DataTablesResponse>(
				`${this.baseUrl}/api/company/datatable`,
				dataTablesParameters, {}
			)
  }
  create(company) {
    return this.http.post<any>(`${this.baseUrl}/api/company`,company);
  }
  edit(company) {
    return this.http.put<any>(`${this.baseUrl}/api/company`,company);
  }
  erase(id) {
    return this.http.delete<any>(`${this.baseUrl}/api/company/${id}`);
  }
  all(){
    return this.http.get<any>(`${this.baseUrl}/api/companies`);
  }
  externalChileanRutChecker(rut){
    return this.http.get<any>(`https://siichile.herokuapp.com/consulta?rut=${rut}`);
  }

}
