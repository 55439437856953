import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Condition } from '@models/condition';
import { DataTablesResponse } from '@models/datatables-response';
@Injectable({
  providedIn: 'root'
})
export class ConditionsService {

  public condition: any = null;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {

  }

  create(condition) {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/condition`,condition);
  }

  getDatatable(dataTablesParameters) {
    return this.http.post<DataTablesResponse>(
      `${this.baseUrl}/api/parameter/condition/datatable`,
      dataTablesParameters, {})

  }
  edit(condition) {
    return this.http.put<any>(`${this.baseUrl}/api/parameter/condition`,condition);
  }
  deleteCondition(id) {
    return this.http.delete<any>(`${this.baseUrl}/api/parameter/condition/${id}`);
  }
  getGroupCondition() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/group`, this.condition);
  }

}
