import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      let token = JSON.parse(localStorage.getItem('token'));

      if (token) {
          request = request.clone({
              setHeaders: { 
                  Authorization: `Bearer ${token.token}`
              }
          });
      }

      return next.handle(request);
  }
}