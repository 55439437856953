import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Language } from '@models/language';
import { DataTablesResponse } from '@models/datatables-response';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  public user:any = null;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
          this.user = user;
      }
    }


  private isDefined(obj,property){
    return ((typeof obj[property])!=='undefined');
  }

  getDatatable(dataTablesParameters){
    return this.http
			.post<DataTablesResponse>(
				`${this.baseUrl}/api/parameter/language/datatables`,
				dataTablesParameters, {}
			)
  }

  getUser() {
    return this.http.get<any>(`${this.baseUrl}/api/auth/user`);
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.baseUrl}/api/auth/login`, { email:email, password:password })
        .pipe(map(token => {
            // login successful if there's a jwt token in the response
            if (token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', JSON.stringify(token));

                if(!environment.production)
                  console.log(token);

                  this.getUser().subscribe((data)=>{

                    if(!environment.production)
                      console.log(data);



                    if(this.isDefined(data,'user')){
                      localStorage.setItem('user', JSON.stringify(data.user));
                      this.user = data.language;
                    }


                  });

                /*this.getUser().pipe(map(user=>{
                  localStorage.setItem('user', JSON.stringify(user));
                }));*/
            }

            return token;
        }));
  }

  create(language) {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/language`,language);
  }

  edit(language) {
    return this.http.put<any>(`${this.baseUrl}/api/parameter/language`,language);
  }

  get() {
    return this.http.get<any>(`${this.baseUrl}/api/parameter/language`);
  }

  erase(id) {
    return this.http.delete<any>(`${this.baseUrl}/api/parameter/language/${id}`);
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('token');
  }
}
