import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GenericService {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
  ) { }
  get(entity) {
    return this.http.get<any>(`${this.baseUrl}/api/${entity}`);
  }
  post(entity, obj) {
    return this.http.post<any>(`${this.baseUrl}/api/${entity}`, obj);
  }
  put(entity, obj) {
    return this.http.put<any>(`${this.baseUrl}/api/${entity}`, obj);
  }
  delete(entity, id) {
    return this.http.delete<any>(`${this.baseUrl}/api/${entity}/${id}`);
  }
}
