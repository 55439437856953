import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Document } from '@models/document';
import { DataTablesResponse } from '@models/datatables-response';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  public document: any = null;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
      /*let document = JSON.parse(localStorage.getItem('user'));
      if (user) {
          this.user = user;
      }*/
    }




  getDatatable(dataTablesParameters) {
    return this.http.post<DataTablesResponse>(
      `${this.baseUrl}/api/parameter/document/datatable`,
      dataTablesParameters, {})

  }

  getDocumentCountry() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document/countries`, document);
  }

  getDocumentEntity() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document/entity_document`, document);
  }

  getDocumentTypeFile() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document/type_file_documents`, document);
  }

  getDocumentPeriodicity() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document/periodicity`, document);
  }

  getDocumentType() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document/type_documents`, document);

  }

  getDocumentMonths() {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document/months_documents`, document);

  }

  create(document) {
    return this.http.post<any>(`${this.baseUrl}/api/parameter/document`, document);
  }


  deleteDocument(id) {
    return this.http.delete<any>(`${this.baseUrl}/api/parameter/document/${id}`);
  }
  editDocument(document) {
    console.log(document)
    return this.http.put<any>(`${this.baseUrl}/api/parameter/document`, document);
  }

  getConditions(){
    return this.http.get<any>(`${this.baseUrl}/api/parameter/conditions`);
  }
  getCategories(){
    return this.http.get<any>(`${this.baseUrl}/api/parameter/categories`);
  }




}
