import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CoreService } from '@core/core.service';
import { ModalDirective, } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@environments/environment'
@Component({
  selector: 'app-dashboard',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.css']
})
export class CommonLayoutComponent implements OnInit {
  @ViewChild('youtubeModal') public youtubeModal: ModalDirective;
  @ViewChild('youtubeModalHHAdmin') public youtubeModalHHAdmin: ModalDirective;
  @ViewChild('youtubeModalHHContractor') public youtubeModalHHContractor: ModalDirective;
  @ViewChild('youtubeModalHHReviewer') public youtubeModalHHReviewer: ModalDirective;
  @ViewChild('content') content: TemplateRef<any>;  

  public app: any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;
  public searchActived: any;
  public searchModel: any;
  public openedModal: any = false;
  subContract = false;
  preload = false;
  //Fase 2
  modalContentTabs = [false,false,false,true];

  generalDocs = [];
  gcDocs = [];

  docTabs = [true,false];

  constructor(
    private modalService: NgbModal,
    public core: CoreService,
    private router: Router,
  ) {
    this.app = {
      layout: {
        sidePanelOpen: false,
        isMenuOpened: false,
        isMenuCollapsed: true,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };
    this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    this.changeHeader = changeHeader;
    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }
    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.changeSidenav = changeSidenav;
    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }
    if (environment.baseCountry != 'morocco') {
      this.core.users.modalVideoShow.subscribe((value) => {
        if (value == null || value == false) {
          setTimeout(() => this.openYouTubeModal(), 1000);
        }
      });
    }

    

    this.setSubContract();
  }

  ngAfterViewInit(){

    setTimeout(() => {
      
      
      let currentDate = (new Date().getTime());

        if(currentDate<=1638324000000){

        let reportsUpdate = localStorage.getItem('reportsUpdate');
        if((typeof reportsUpdate)  !== 'undefined'){
            if(reportsUpdate!='true'){
                if(typeof this.core.users.user.employers !=='undefined'){
                  if(this.core.users.user.employers.length>0){
                    this.actualizacionModal();
                  }
                }
                localStorage.setItem('reportsUpdate','true');
            }
        }else{
            if(typeof this.core.users.user.employers !=='undefined'){
              if(this.core.users.user.employers.length>0){
                this.actualizacionModal();
              }
            }
            localStorage.setItem('reportsUpdate','true');
        }

        }

    }, 1000);

  }

  setSubContract() {
    console.log(this.core.users.user.employers);
    for (let i in this.core.users.user.employers) {
      if (this.core.users.user.employers[i].id == 1) {
        this.subContract = false;
        break;
      } else {
        this.subContract = true;
      }
      if(typeof this.core.users.user.employers[i].contractor_party !=='undefined'){
        let stop = false;
        for(let contractor_party_company of this.core.users.user.employers[i].contractor_party){
          if (contractor_party_company.id == 1) {
            this.subContract = false;
            stop = true;
            break;
          } else {
            this.subContract = true;
          }
          if(typeof contractor_party_company.contractor_party !=='undefined'){
            let stop2 = false;
            for(let contractor_party_company2 of contractor_party_company.contractor_party){
              if (contractor_party_company2.id == 1) {
                this.subContract = false;
                stop2 = true;
                break;
              } else {
                this.subContract = true;
              }
            }
            if(stop2){
              break;
            }
          }
        }
        if(stop){
          break;
        }
      }
    }
  }

  ngOnInit() {
    console.log(this.core.users.user);

    this.core.acreditation.get('reposited_documents/1').subscribe(response => {
      if(typeof response.response.repositedDocuments !== 'undefined'){
        this.generalDocs = response.response.repositedDocuments;
      }
    });

    this.core.acreditation.get('reposited_documents/2').subscribe(response => {
      if(typeof response.response.repositedDocuments !== 'undefined'){
        this.gcDocs = response.response.repositedDocuments;
      }
    });
    
  }
  logout() {
    this.core.users.logout();
  }
  openYouTubeModal() {
    let user = {
      id: this.core.users.user.id,
      name: this.core.users.user.name,
      email: this.core.users.user.email,
      video: 1
    };
    this.core.users.edit(user).subscribe(() => { });
    this.core.users.user.video = 1;
    localStorage.setItem('user', JSON.stringify([this.core.users.user]));
    this.core.users.modalVideoShow.next(true);
    this.openedModal = true;
    this.youtubeModal.show();
  }
  closeYouTubeModal() {
    this.openedModal = false;
    this.youtubeModal.hide();
  }
  navigateTo(url) {
    localStorage.removeItem('contractor');
    localStorage.removeItem('contract');
    localStorage.removeItem('doc');
    localStorage.removeItem('employee');
    localStorage.removeItem('vehicle');
    localStorage.removeItem('tool');
    localStorage.removeItem('work');
    localStorage.removeItem('backToTree');
    localStorage.removeItem('backToResults');
    if (url == '/man-hours/') {
      if (
        this.core.users.checkPermission('HH', 'GLOBAL', 'VIEW')
        && this.core.users.checkPermission('HH', 'GLOBAL', 'EDIT')
        && this.core.users.checkPermission('HH', 'GLOBAL', 'REVIEW')
        && this.core.users.checkPermission('HH', 'GLOBAL', 'LOG')
      ) {
        url += 'admin';
      } else if (
        this.core.users.checkPermission('HH', 'GLOBAL', 'VIEW')
        && this.core.users.checkPermission('HH', 'GLOBAL', 'REVIEW')
      ) {
        url += 'review';
      } else if (
        this.core.users.checkPermission('HH', 'GLOBAL', 'VIEW')
        && this.core.users.checkPermission('HH', 'GLOBAL', 'EDIT')
      ) {
        url += 'contractor';
      }
    }
    this.router.navigate([url]);
  }
  openXl(tutoriales) {
    this.modalService.open(tutoriales, { size: 'lg' });
  }

  openYouTubeModalHHAdmin() {
    this.openedModal = true;
    this.youtubeModalHHAdmin.show();
  }

  closeYouTubeModalHHAdmin() {
    this.openedModal = false;
    this.youtubeModalHHAdmin.hide();
  }

  openYouTubeModalHHContractor() {
    this.openedModal = true;
    this.youtubeModalHHContractor.show();
  }

  closeYouTubeModalHHContractor() {
    this.openedModal = false;
    this.youtubeModalHHContractor.hide();
  }

  openYouTubeModalHHReviewer() {
    this.openedModal = true;
    this.youtubeModalHHReviewer.show();
  }

  closeYouTubeModalHHReviewer() {
    this.openedModal = false;
    this.youtubeModalHHReviewer.hide();
  }

  useLanguage(language: string) {
    this.core.changeLang(language);
  }

  actualizacionModal() {
    console.log(this.content);
    this.modalService.open(this.content, { size: 'xl' });
  }

  goHelp(modal){
    modal.close('Close click');
    this.navigateTo('/ayuda');
  }

  selectActiveContent(index){
    this.modalContentTabs.forEach((val,i)=>{this.modalContentTabs[i]=false});
    this.modalContentTabs[index] = true;
  }

  // modalPrueba() {
  //   console.log(this.content);
  //   this.modalService.open(this.content, { size: 'xl' });
  // }
}
