import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgSelectizeModule } from 'ng-selectize';

import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//Layout Modules
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';

//Directives
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Sidebar_Directives } from '@shared/directives/side-nav.directive';
import { Cards_Directives } from '@shared/directives/cards.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ModalModule } from 'ngx-bootstrap/modal';

// Routing Module
import { AppRoutingModule } from './app-routing.module';

// App Component
import { AppComponent } from './app.component';

import { ErrorInterceptor } from './helpers/error.interceptor.service';
import { JwtInterceptor } from './helpers/jwt.interceptor.service';
import { CommonModule } from '@angular/common';
import { CoreService } from '@core/core.service';
import { RouterExtService } from '@core/router_ext/router.ext.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'angular2-moment';

import { UiSwitchModule } from 'ngx-ui-switch';

//Tree
import { TreeModule } from 'angular-tree-component';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  imports: [
    HttpClientModule,
    UiSwitchModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['wisefollow.com', 'localhost:8000'],
        blacklistedRoutes: []
      }
    }),
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    //RouterModule.forRoot(AppRoutes, { useHash: true, anchorScrolling:'enabled' }),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PerfectScrollbarModule,
    ModalModule.forRoot(),
    TreeModule.forRoot(),
    NgSelectizeModule,
    BrowserAnimationsModule,
    MomentModule
  ],
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    AuthenticationLayoutComponent,
    Sidebar_Directives,
    Cards_Directives
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CoreService,
    RouterExtService
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
