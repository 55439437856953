import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GyresService {
  baseUrl = environment.baseUrl;
  company:any;
  constructor(private http: HttpClient) {}

  create(gyre) {
    return this.http.post<any>(`${this.baseUrl}/api/company-gyre`,gyre);
  }
  edit(gyre) {
    return this.http.put<any>(`${this.baseUrl}/api/company-gyre`,gyre);
  }
  erase(id) {
    return this.http.delete<any>(`${this.baseUrl}/api/company-gyre/${id}`);
  }
  get_all() {
    return this.http.get<any>(`${this.baseUrl}/api/company-gyres`);
  }
}
